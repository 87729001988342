<template>
  <div class="modifyInfo">
    <div class="modifyTitle">
      修改个人资料
      <img src="../assets/back.png" @click="goBack()" alt="" />
    </div>
    <div class="infoList">
      <div class="infoItem headImg">
        <div class="left">头像</div>
        <div class="right">
          <input type="file" name="image" @change="changeFile" />
          <img class="infoImg" :src="userInfo.head_image" alt="" />
        </div>
      </div>
      <div class="infoItem nickName">
        <div class="left">昵称</div>
        <div class="right">
          <input type="text" maxlength="10" v-model="nameValue" />
        </div>
      </div>
      <div class="infoItem">
        <div class="left">手机号</div>
        <div class="right">{{ userInfo.phone }}</div>
      </div>
      <div class="infoItem smrz" @click="goSmrz(userInfo.is_auth)">
        <div class="left">实名认证</div>
        <div class="right">
          {{ userInfo.is_auth === 1 ? "已认证" : "未认证" }}
          <img src="../assets/next.png" alt="" />
        </div>
      </div>
    </div>
    <div class="submitInfo" @click="posrUserInfo">提交</div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted } from "vue";
import { Toast } from "@nutui/nutui";
import { getUserInfo, postImg, postUser } from "../api/person.js";
export default {
  setup() {
    const state = reactive({
      userInfo: "",
      nameValue: "",
      files: "",
      uploadUrl: "https://www.autostay.cn/api/upload/fileUpload",
    });

    onMounted(() => {
      getUserInfoData();
    });
    const getUserInfoData = () => {
      getUserInfo().then((res) => {
        state.userInfo = res.data;
        state.nameValue = state.userInfo.nick_name;
      });
    };
    return {
      ...toRefs(state),
    };
  },
  data() {
    return {
      fileData: "",
    };
  },
  methods: {
    posrUserInfo() {
      let params = {
        nick_name: this.nameValue,
        head_image: this.userInfo.head_image,
      };
      postUser(params).then((res) => {
        if (res.code == 1) {
          Toast.text("提交成功");
        } else {
          Toast.text("提交失败，请稍后再试");
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    changeFile(e) {
      this.fileData = e.target.files[0];
      let formData = new FormData();
      // 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
      formData.append("image", this.fileData);
      postImg(formData).then((res) => {
        this.userInfo.head_image = res.data.path;
      });
    },
    goSmrz(item) {
      this.$router.push({
        path: "/realName",
        query: {
          real: item,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.modifyInfo {
  .nut-uploader {
    width: 100%;
    height: 100%;
  }
  .nut-uploader__preview {
    margin: 0;
  }
  .nut-uploader__preview-img {
    width: 100%;
    height: 100%;
  }
  .nut-uploader__preview__progress {
    background: unset;
  }
  .nut-uploader__upload {
    width: 40px;
    height: 40px;
  }
}
</style>
<style lang="scss" scoped>
.modifyInfo {
  .modifyTitle {
    height: 40px;
    background: #ffffff;
    position: relative;
    font-size: 15px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      width: 16px;
      margin: auto;
    }
  }
  .infoList {
    margin-top: 5px;
    background-color: #ffffff;
    padding: 0 20px;
    .infoItem {
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ececec;
      .left {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
      .right {
        font-size: 14px;
        color: #333333;
      }
    }
    .nickName {
      .right {
        flex: 1;
        margin-left: 5px;
        input {
          width: 100%;
          text-align: right;
        }
      }
    }
    .headImg {
      .right {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
        }
        input {
          width: 100%;
          height: 100%;
          background: unset;
          border: 0;
          outline: none;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }
    .smrz {
      border: 0;
      .right {
        display: flex;
        align-items: center;
        img {
          width: 6px;
          margin-left: 8px;
        }
      }
    }
  }
  .submitInfo {
    width: 345px;
    margin: 0 auto;
    height: 45px;
    background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
    border-radius: 25px;
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    line-height: 45px;
    text-align: center;
    margin-top: 50px;
  }
}
</style>